"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanvasObject = void 0;
exports.registerObject = registerObject;
exports.createObject = createObject;
const event_1 = require("@/common/event");
const lifecycle_1 = require("@/common/lifecycle");
const utils_1 = require("@/common/utils/utils");
const vector_1 = require("./vector");
const force_1 = require("./force");
class CanvasObject extends lifecycle_1.Disposable {
    constructor(obj, mass, velocity, hitbox) {
        super();
        this.obj = obj;
        this.mass = mass;
        this.velocity = velocity;
        this.hitbox = hitbox;
        // events
        this._onPointerDown = new event_1.Emitter();
        this._onPointerMove = new event_1.Emitter();
        this._onPointerUp = new event_1.Emitter();
        this._forces = new vector_1.ForceCollection();
        this._onceForces = new vector_1.ForceCollection();
        this._isInteractive = false;
        this._isHeld = false;
        this._register(this.hitbox);
    }
    _enableInteractivity() {
        this.obj.interactive = true;
        this._isInteractive = true;
        this.obj.addEventListener("pointerdown", (e) => {
            if (!this._isHeld) {
                this._isHeld = true;
                this.velocity = vector_1.Vector.Zero;
                this.obj.x = e.clientX;
                this.obj.y = e.clientY;
                this.updateHitboxAnchor();
            }
            this._onPointerDown.fire(e);
        });
        document.body.addEventListener("pointermove", (e) => {
            if (!this._isHeld)
                return;
            this.obj.x = e.clientX;
            this.obj.y = e.clientY;
            this.updateHitboxAnchor();
            this._onPointerMove.fire(e);
        });
        document.body.addEventListener("pointerup", (e) => {
            if (!this._isHeld)
                return;
            this._isHeld = false;
            this._onPointerUp.fire(e);
        });
    }
    applyForce(key, force) {
        this._forces.add(key, force);
    }
    applyOnceForce(force) {
        this._onceForces.add((0, utils_1.generateRandomID)(), force);
    }
    applyGravity() {
        this._forces.add("gravity", force_1.Force.gravity(this.mass));
    }
    removeForce(key) {
        this._forces.remove(key);
    }
    clearForces() {
        this._forces.clear();
        this._onceForces.clear();
    }
    updateHitboxAnchor() {
        const bound = this.obj.getBounds();
        this.hitbox.setAnchor({
            x: bound.x,
            y: bound.y
        });
    }
    update(delta, container) {
        if (!this._isHeld) {
            const sumForce = force_1.Force.add(this._forces.getSum(), this._onceForces.getSum());
            const accelerate = sumForce.getAccelerate(this.mass);
            this.velocity = vector_1.Vector.add(this.velocity, accelerate);
            this.obj.x += this.velocity.x * delta;
            this.obj.y -= this.velocity.y * delta;
            this.updateHitboxAnchor();
            this._onceForces.clear();
        }
        container.addChild(this.obj);
    }
    get onPointerDown() {
        if (!this._isInteractive)
            throw new Error("This object is not interactive, so you cannot add listener(s) to interactive events.");
        return this._onPointerDown.event;
    }
    get onPointerMove() {
        if (!this._isInteractive)
            throw new Error("This object is not interactive, so you cannot add listener(s) to interactive events.");
        return this._onPointerMove.event;
    }
    get onPointerUp() {
        if (!this._isInteractive)
            throw new Error("This object is not interactive, so you cannot add listener(s) to interactive events.");
        return this._onPointerUp.event;
    }
    dispose() {
        this.clearForces();
        this.velocity = vector_1.Vector.Zero;
        this.obj.destroy();
        super.dispose();
    }
}
exports.CanvasObject = CanvasObject;
const objMap = new Map();
function registerObject(id, obj) {
    objMap.set(id, obj);
}
function createObject(id, ...args) {
    const objClass = objMap.get(id);
    if (!objClass) {
        throw new Error("Specified object doesn't exist.");
    }
    return new objClass(...args);
}
