"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@/style/main.less");
const global_1 = require("./common/global");
const simulator_1 = require("./simulator");
console.log("\n%cMotive%cv" + global_1.version + " | Nocpiun Org\n" +
    "%cA web-based physics simulator.\n\n" +
    "%cWebpage: %cComing soon...\n" +
    "%cGithub Repo: https://github.com/nocpiun/motive\n\n" +
    "%cDonate: https://nin.red/#/donate\n", "font-family: Consolas;font-size: 17pt;font-weight: bold;padding: 10px", "font-family: Consolas;font-size: 8pt;color: gray", "font-family: Consolas;font-size: 8pt;color: white", "font-family: Consolas;font-size: 8pt;color: white", "font-family: Consolas;font-size: 8pt;color: gray;font-style: italic", "font-family: Consolas;font-size: 8pt;color: white", "font-family: Consolas;font-size: 8pt;color: white");
const root = document.getElementById("root");
new simulator_1.Motive(root);
