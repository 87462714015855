"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Render = void 0;
const PIXI = __importStar(require("pixi.js"));
const lifecycle_1 = require("@/common/lifecycle");
const linkedNodes_1 = require("@/common/utils/linkedNodes");
const ground_1 = require("@/simulator/objects/ground");
const colors_1 = require("./colors");
class Render extends lifecycle_1.Disposable {
    constructor(_canvas) {
        super();
        this._canvas = _canvas;
        this._container = new PIXI.Container({ x: 0, y: 0 });
        this._objects = linkedNodes_1.LinkedNodes.empty();
        this._prerenderObjects = linkedNodes_1.LinkedNodes.empty();
        this.isPaused = false;
        this.isMouseMode = false;
        this._register(this._canvas.onLoad((app) => {
            this._app = app;
            // Invisible background for interactions
            const bg = new PIXI.Graphics().rect(0, 0, this._canvas.width, this._canvas.height).fill(colors_1.colors["transparent"]);
            this._app.stage.addChild(bg);
            // Container
            this._container.width = this._canvas.width;
            this._container.height = this._canvas.height;
            this._app.stage.addChild(this._container);
            this._init();
            this._initTimer();
        }));
    }
    _init() {
        this._objects.push(new ground_1.Ground(this._app.canvas));
        // this._objects.push(new Ball(100, this._app.canvas.height - Ground.GROUND_HEIGHT - 15, 15, 1, new Vector(8, 0)));
        // this._objects.push(new Ball(100, 200, 15, 1, new Vector(1, 0)));
        // this._objects.push(new Ball(170, this._app.canvas.height - Ground.GROUND_HEIGHT - 15, 15, 1, new Vector(0, 0)));
    }
    _initTimer() {
        this._app.ticker.add((ticker) => {
            this.update(ticker.deltaTime);
        });
    }
    addObject(obj) {
        this._prerenderObjects.push(obj);
        this._objects.push(obj);
    }
    clearObjects() {
        for (const obj of this._objects) {
            obj.dispose();
        }
        this._objects.clear();
    }
    refresh() {
        this.clearObjects();
        this._init();
    }
    pause() {
        if (this.isPaused)
            return;
        this.isPaused = true;
    }
    unpause() {
        if (!this.isPaused)
            return;
        this.isPaused = false;
    }
    setMouseMode(enabled) {
        this.isMouseMode = enabled;
    }
    update(delta) {
        // Pre-rendering stage
        if (!this._prerenderObjects.isEmpty()) {
            for (const obj of this._prerenderObjects) {
                obj.update(delta, this._container);
            }
            this._prerenderObjects.clear();
        }
        // Rendering stage
        if (this.isPaused)
            return;
        // Repaint the canvas
        this._container.removeChildren();
        for (const obj of this._objects) {
            obj.update(delta, this._container);
            for (const _obj of this._objects) {
                if (_obj !== obj) {
                    obj.hitbox.test(_obj);
                }
            }
        }
    }
    dispose() {
        this._app.ticker.stop();
        this.clearObjects();
        super.dispose();
    }
}
exports.Render = Render;
