"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Force = void 0;
const global_1 = require("@/common/global");
const vector_1 = require("./vector");
class Force extends vector_1.Vector {
    constructor(x, y) {
        super(x, y);
    }
    getAccelerate(mass) {
        return vector_1.Vector.multiplyScalar(this, 1 / mass);
    }
    setSize(size) {
        const length = this.length; // To avoid duplicated calculation of vector length
        const sin = this.y / length;
        const cos = this.x / length;
        this.x = size * cos;
        this.y = size * sin;
    }
    setToZero() {
        this.x = this.y = 0;
    }
    static gravity(mass) {
        return new Force(0, -mass * global_1.gravity);
    }
    static from(vector) {
        return new Force(vector.x, vector.y);
    }
    static add(vector1, vector2) {
        return new Force(vector1.x + vector2.x, vector1.y + vector2.y);
    }
    static sub(vector1, vector2) {
        return new Force(vector1.x - vector2.x, vector1.y - vector2.y);
    }
    static multiplyScalar(vector, scalar) {
        return new Force(vector.x * scalar, vector.y * scalar);
    }
    static reverse(vector) {
        return new Force(-vector.x, -vector.y);
    }
}
exports.Force = Force;
