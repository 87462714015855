"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lucide_1 = require("lucide");
const event_1 = require("@/common/event");
const ui_1 = require("@/ui/ui");
const buttonGroup_1 = require("@/ui/button/buttonGroup");
const switcher_1 = require("@/ui/switcher/switcher");
const modalProvider_1 = require("@/ui/modal/modalProvider");
const contextMenuProvider_1 = require("@/ui/contextMenu/contextMenuProvider");
require("./panel.less");
const defaultOptions = {
    width: 630,
    height: 150
};
class Panel extends ui_1.Component {
    constructor(target, _options) {
        super((0, jsx_runtime_1.jsx)("div", { className: "panel" }), target, defaultOptions, _options);
        this._renderer = null;
        // events
        this._onSelectedObjectChange = new event_1.Emitter();
        this._isPoppedUp = false;
        this._isPinned = false;
        this._withdrawTimer = null;
        this._switchers = [];
        if (this._options.width)
            this._element.style.width = `${this._options.width}px`;
        if (this._options.height)
            this._element.style.height = `${this._options.height}px`;
        // UI
        const toolbar = (0, ui_1.createElement)("div", this);
        toolbar.classList.add("panel-toolbar");
        const toolbarLeftGroup = new buttonGroup_1.ButtonGroup(toolbar);
        toolbarLeftGroup.addButton({ icon: lucide_1.Settings, tooltip: "设置" }, () => modalProvider_1.modalProvider.open("settings"));
        toolbarLeftGroup.addButton({ icon: lucide_1.Box, tooltip: "管理" }, () => modalProvider_1.modalProvider.open("manager"));
        toolbarLeftGroup.addSwitcher({ icon: lucide_1.MousePointer2, tooltip: "鼠标模式" }, ({ isActive }) => this._renderer.setMouseMode(isActive));
        this._refreshButton = toolbarLeftGroup.addButton({ icon: lucide_1.RotateCw, tooltip: "刷新" });
        this._pauseSwitcher = toolbarLeftGroup.addSwitcher({ icon: lucide_1.Pause, tooltip: "暂停" }, ({ isActive }) => {
            isActive ? this._pauseRenderer() : this._unpauseRenderer();
        });
        const toolbarRightGroup = new buttonGroup_1.ButtonGroup(toolbar);
        toolbarRightGroup.addButton({ icon: lucide_1.Info, tooltip: "关于" }, () => modalProvider_1.modalProvider.open("about"));
        this._pinSwitcher = toolbarRightGroup.addSwitcher({ icon: lucide_1.Pin, tooltip: "固定" }, ({ isActive }) => {
            isActive ? this._pin() : this._unpin();
        });
        this._closeButton = toolbarRightGroup.addButton({ icon: lucide_1.X }, () => this._withdraw(false));
        const switcherContainer = (0, ui_1.createElement)("div", this);
        switcherContainer.classList.add("panel-switcher-container");
        // Context Menu
        contextMenuProvider_1.contextMenuProvider.registerContextMenu(this, [
            {
                text: "刷新画面",
                icon: lucide_1.RotateCw,
                action: () => {
                    if (this._renderer.isPaused)
                        return;
                    this._renderer.refresh();
                    this._withdraw(false);
                }
            },
            { separator: true },
            {
                text: "设置",
                icon: lucide_1.Settings,
                action: () => modalProvider_1.modalProvider.open("settings")
            },
            {
                text: "管理",
                icon: lucide_1.Box,
                action: () => modalProvider_1.modalProvider.open("manager")
            },
            {
                text: "关于 Motive",
                icon: lucide_1.Info,
                action: () => modalProvider_1.modalProvider.open("about")
            }
        ]);
        // Listeners
        this._element.addEventListener("mouseenter", () => this._popUp());
        this._element.addEventListener("mouseleave", () => this._withdraw());
    }
    _popUp() {
        if (this._withdrawTimer) {
            clearTimeout(this._withdrawTimer);
            this._withdrawTimer = null;
        }
        if (this._isPoppedUp)
            return;
        this._isPoppedUp = true;
        this._element.classList.add("panel-popped-up");
    }
    _withdraw(shouldTimerSet = true) {
        if (!this._isPoppedUp || this._isPinned)
            return;
        if (shouldTimerSet) {
            this._withdrawTimer = setTimeout(() => {
                this._isPoppedUp = false;
                this._element.classList.remove("panel-popped-up");
            }, Panel.WITHDRAW_TIME);
        }
        else {
            this._isPoppedUp = false;
            this._element.classList.remove("panel-popped-up");
        }
    }
    _pin() {
        if (this._isPinned)
            return;
        this._isPinned = true;
        this._popUp();
        this._pinSwitcher.setTooltip("取消固定");
        this._closeButton.disabled = true;
    }
    _unpin() {
        if (!this._isPinned)
            return;
        this._isPinned = false;
        this._pinSwitcher.setTooltip("固定");
        this._closeButton.disabled = false;
    }
    _pauseRenderer() {
        this._renderer.pause();
        this._pauseSwitcher.setIcon(lucide_1.Play);
        this._pauseSwitcher.setTooltip("继续");
        this._refreshButton.disabled = true;
    }
    _unpauseRenderer() {
        this._renderer.unpause();
        this._pauseSwitcher.setIcon(lucide_1.Pause);
        this._pauseSwitcher.setTooltip("暂停");
        this._refreshButton.disabled = false;
    }
    linkRenderer(renderer) {
        this._renderer = renderer;
        this._register(this._renderer);
        this._register(this._refreshButton.onClick(() => {
            this._renderer.refresh();
            this._withdraw(false);
        }));
        this._register(modalProvider_1.modalProvider.onModalOpen(() => this._pauseRenderer()));
        this._register(modalProvider_1.modalProvider.onModalClose(() => this._unpauseRenderer()));
        for (const switcher of this._switchers) {
            this._register(switcher.onDidChange(({ id, isActive }) => {
                this._onSelectedObjectChange.fire(id.replace("btn.obj.", ""));
                if (!isActive) {
                    switcher.setActive(true);
                }
                for (const _switcher of this._switchers) {
                    if (_switcher.id !== id) {
                        _switcher.setActive(false);
                    }
                }
            }));
        }
    }
    addObjectSwitcher(id, displayName, icon, disabled = false, defaultValue = false) {
        const switcherContainer = document.querySelector(".panel-switcher-container");
        const switcher = new switcher_1.Switcher(switcherContainer, {
            id: "btn.obj." + id,
            text: displayName,
            icon,
            disabled,
            defaultValue,
            contextMenuItems: [
                {
                    text: "选择",
                    action: () => switcher.select()
                },
                { separator: true },
                {
                    text: "参数属性",
                    icon: lucide_1.Wrench,
                    action: () => { }
                }
            ]
        });
        this._switchers.push(switcher);
        return switcher;
    }
    get onSelectedObjectChange() {
        return this._onSelectedObjectChange.event;
    }
}
exports.Panel = Panel;
Panel.WITHDRAW_TIME = 1500; // ms
