"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
const _colors = {
    "transparent": 0xffffff,
    "white": 0xffffff,
    "black": 0x222222,
    "gray": 0xb3b3b3,
    "wood": 0xbd6f1c,
    "skyBlue": 0x7dd3fc,
};
exports.colors = _colors;
